.admin-dashboard {
  width: 100vw;
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr;
  position: absolute;
}

.admin-dashboardContainer {
  border-left: 1px solid rgba(0, 0, 0, 0.13);
  background-color: rgb(255, 255, 255);
  padding: 3rem 0;
}

.admin-dashboardContainer > h1 {
  color: rgba(0, 0, 0, 0.733);
  font: 300 2rem "Roboto";
  text-align: center;
  width: 50%;
  padding: 1.5rem;
  margin: auto;
}

.admin-dashboardSummary {
  margin: 2rem 0;
}

.admin-dashboardSummary > div {
  display: flex;
  background-color: white;
  justify-content: center;
}
.admin-dashboardSummary > div > p {
  background-color: rgba(70, 117, 218, 0.932);
  color: white;
  font: 300 1.3rem "Roboto";
  text-align: center;
  padding: 1.5rem;
  width: 100%;
  margin: 0 2rem;
}
.admin-dashboardSummaryBox2 > a {
  color: rgb(0, 0, 0);
  font: 300 2rem "Roboto";
  text-align: center;
  background-color: rgb(255, 233, 174);
  text-decoration: none;
  padding: 1.5rem;
  width: 10vmax;
  height: 10vmax;
  margin: 2rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.admin-dashboardSummaryBox2 > a:first-child {
  background-color: rgb(255, 110, 110);
  color: rgb(255, 255, 255);
}

.admin-dashboardSummaryBox2 > a:last-child {
  background-color:rgb(255, 110, 110);
  color: rgb(255, 255, 255);
}

.admin-lineChart {
  width: 80%;
  margin: auto;
}

.admin-doughnutChart {
  width: 30vmax;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .admin-dashboard {
    grid-template-columns: 1fr;
  }

  .admin-dashboardContainer {
    border-left: none;
  }

  .admin-dashboardSummary > div > p {
    margin: 0;
  }

  .admin-dashboardSummaryBox2 > a {
    padding: 0.5rem;
    margin: 1rem;
    font: 300 0.9rem "Roboto";
  }
}
