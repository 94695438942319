/* header modal styling */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  width: 100vw;
  top: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  z-index: 999;
}

section{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover;
}

.close-btn {
  position: fixed;
  right: 40px;
  top: 30px;
  color: crimson;
  font-size: 40px;
  font-weight: bold;
}

.form-box {
  position: relative;
  width: 400px;
  height: 600px;
  background: rgb(130, 9, 236);
  background: linear-gradient(
    0deg,
    hsl(170, 75%, 41%) 0%,
    hsl(351, 83%, 61%) 99%
  );
  border: 2px solid hsl(0, 0%, 73%);
  border-radius: 20px;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 440px) {
  .form-box {
    width: 350px;
  }
}

.heading {
  font-size: 2em;
  color: #fff;
  text-align: center;
}
.inputbox {
  position: relative;
  margin: 30px 0;
  width: 310px;
  border-bottom: 2px solid #fff;
}
.inputbox label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  color: #fff;
  font-size: 1em;
  pointer-events: none;
  transition: 0.5s;
}
input:focus ~ label,
input:valid ~ label {
  top: -5px;
}
.inputbox input {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  padding: 0 35px 0 5px;
  color: #fff;
}
.inputbox ion-icon {
  position: absolute;
  right: 8px;
  color: #fff;
  font-size: 1.2em;
  top: 20px;
}
.forget {
  margin: -15px 0 15px;
  font-size: 0.9em;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.forget label input {
  margin-right: 3px;
}
.forget label a {
  color: #fff;
  text-decoration: none;
}
.forget label a:hover {
  text-decoration: underline;
}
.login_btn {
  width: 100%;
  height: 40px;
  border-radius: 40px;
  background: hsl(0, 0%, 73%);
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
}

.register {
  font-size: 0.9em;
  color: #fff;
  text-align: center;
  margin: 25px 0 10px;
  cursor: pointer;
}
.register p a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
}
.register p a:hover {
  text-decoration: underline;
}
