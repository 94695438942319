.blog-card .card-content {
    position: relative;
    margin-inline: -12px;
    background-color: rgba(255, 255, 255, 0.3);
    text-align: center;
    /* padding: 4vmax; */
    border-radius: var(--radius-10);
    box-shadow: var(--shadow-4);
    margin-block-start: -2px;
    z-index: 1;
}

.card-buttons-user{
    display: flex;
    justify-content: space-between;
    margin-top: 51px;
    flex-direction: column;
    align-items: center;
}