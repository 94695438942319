* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* :root {
  --hue: 223;
  --bg: hsl(var(--hue), 90%, 95%);
  --fg: hsl(var(--hue), 90%, 5%);
  --trans-dur: 0.1s;
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
} */

.loderbody{
  background-color: hsl(36, 33%, 94%);
  color: hsl(223, 90%, 5%);;
  font: 1em/1.5 sans-serif;
  height: 100vh;
  display: grid;
  place-items: center;
  transition: background-color 0.1s;
}

main {
  padding: 1.5em 0;
}

.ip {
  width: 16em;
  height: 8em;
}

.ip__track {
  stroke: hsl(223, 90%, 90%);
  transition: stroke 0.1s;
}

.ip__worm1,
.ip__worm2 {
  animation: worm1 1s linear infinite;
}

.ip__worm2 {
  animation-name: worm2;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
      --bg: hsl(223, 90%, 5%);
      --fg: hsl(223, 90%, 95%);
  }

  .ip__track {
      stroke: hsl(223, 90%, 15%);
  }
}

/* Animation */
@keyframes worm1 {
  from {
      stroke-dashoffset: 0;
  }

  50% {
      animation-timing-function: steps(1);
      stroke-dashoffset: -358;
  }

  50.01% {
      animation-timing-function: linear;
      stroke-dashoffset: 358;
  }

  to {
      stroke-dashoffset: 0;
  }
}

@keyframes worm2 {
  from {
      stroke-dashoffset: 358;
  }

  50% {
      stroke-dashoffset: 0;
  }

  to {
      stroke-dashoffset: -358;
  }
}