.myModal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

/* Style for the modal content */
.modal-content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    padding: 20px;
    width: 80%;
    max-width: 800px;
    background-color: #fefefe;
    text-align: center;
}

/* Style for the close button */
.close {
    position: absolute;
    top: 20px;
    right: 23px;
    font-size: 30px;
    color: #aaa;
    cursor: pointer;
    border: none;
}

/* Style for the button that opens the modal */
.button {
    /* padding: 10px 20px; */
    background-color: #f50057;
    color: white;
    border: none;
    cursor: pointer;
}