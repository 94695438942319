

.box {
    width: 83rem;
    height: auto;
  }
  
  .main-container {
    width: 100%;
    height: 63rem;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.5);
  }
  
  .img-container {
    width: 50%;
    height: 100%;
    padding: 4rem 1rem;
    background: linear-gradient(
    0deg,
    hsl(170, 75%, 41%) 0%,
    hsl(351, 83%, 61%) 99%
  );
    animation: slide-down 0.5s ease;
  }
  
  @keyframes slide-down {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .img-container img {
    width: 100%;
    height: 100%;
  }
  
  .form-container {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background:#fff;
    animation: slide-up 0.5s ease;
  }
  
  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  form {
    width: 90%;
  }
  
  form a {
    text-decoration: none;
  }
  
  .reset {
    position: relative;
  }
  
  .close {
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 3rem;
    width: 3rem;
    border: 1px solid red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  @media screen and (max-width: 520px) {
    .img-container {
      display: none;
    }
    .form-container {
      width: 100%;
    }
  }
  
  /* // Password Visibility Toggle */
  .password {
    position: relative;
    .icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }
  }
  
  .hide-indicator {
    display: none;
  }
  .show-indicator {
    display: block;
    animation: slide-up 0.5s ease;
  }
  
  .pass-green {
    color: green;
  }
  .pass-red {
    color: red;
  }
  
  .btn-disabled {
    position: relative;
    cursor: not-allowed;
  }
  
  .btn-disabled::after {
    content: "";
    position: absolute;
    left: 0;
    top: o;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 999;
  }
  